<template>
  <b-container class="mt-4 navbar__main ">
    <b-row class="p-3">
      <b-col :cols="$i18n.locale === 'es' ? 3 : 4" class="pr-0" width="100px">
        <b-row class="align-items-center mb-3">
          <span class="font__heavy">{{ $t("navbarInfo.sedeOrigen") }}:</span>
          <b-dropdown
            id="dropdown-1"
            size="sm"
            :text="sedeOrigen"
            variant="outline-white"
            :disabled="pathName === 'Recipe'"
          >
            <template v-if="country === 'US'">
              <div class="header__dropdown">
                <img src="../assets/images/eeuu-flag.svg" alt="" />
                <b>{{ $t("navbarInfo.USA") }}</b>
              </div>
              <div v-for="(item, index) in origenUS" :key="index" class="item__dropdown">
                <b-dropdown-item :data-name="item" @click="clickSede(item, 'origen')">
                  {{ item.label }}
                </b-dropdown-item>
              </div>
            </template>
            <template v-else>
              <div class="header__dropdown">
                <img src="../assets/images/mexico-flag.svg" alt="" />
                <b>México</b>
              </div>
              <div v-for="(item, index) in origenMX" :key="index + 5" class="item__dropdown">
                <b-dropdown-item :data-name="item" @click="clickSede(item, 'origen')">
                  {{ item.label }}
                </b-dropdown-item>
              </div>
            </template>
          </b-dropdown>
        </b-row>
        <b-row class="align-items-center mb-3">
          <span class="font__heavy">{{ $t("navbarInfo.sedeDestino") }}:</span>
          <b-dropdown id="dropdown-2" size="sm" :text="sedeDestino" variant="outline-white">
            <div class="header__dropdown">
              <img src="../assets/images/eeuu-flag.svg" alt="" />
              <b>{{ $t("navbarInfo.USA") }}</b>
            </div>
            <div v-for="(item, index) in destinoUS" :key="index" class="item__dropdown">
              <b-dropdown-item :data-name="item" @click="clickSede(item, 'destino')">
                {{ item.label }}
              </b-dropdown-item>
            </div>
            <b-dropdown-divider></b-dropdown-divider>
            <div class="header__dropdown">
              <img src="../assets/images/mexico-flag.svg" alt="" />
              <b>México</b>
            </div>
            <div v-for="(item, index) in destinoMX" :key="index + 5" class="item__dropdown">
              <b-dropdown-item :data-name="item" @click="clickSede(item, 'destino')">
                {{ item.label }}
              </b-dropdown-item>
            </div>
            <div>
            </div>
          </b-dropdown>
        </b-row>
        <b-row class="align-items-center mb-3">
          <b class="font__heavy pr-2">
            <!-- {{ $t("navbarInfo.labeltipo") }}: -->
          </b>
                <!-- {{ tipo }} -->
        </b-row>
      </b-col>
      <b-col :cols="$i18n.locale === 'es' ? 3 : 2">
        <b-form>
          <b-row class="align-items-center mb-3">
            <!-- <img src="../assets/images/mujer-icon.png" width="30" alt="" /> -->
            <span class="font__heavy pr-2">{{ $t("navbarInfo.patientName") }}:</span>
            {{ nombrePaciente }}
          </b-row>
          <b-row class="align-items-center mb-3">
            <b class="font__heavy pr-2">NHC:</b>
            {{ NHC }}
          </b-row>
          <b-row class="align-items-center mb-3">
            <b class=" font__heavy pr-2">{{ $t("navbarInfo.labelperfil") }}:</b>
            <span class="emoji">{{ score_azul_verde }} {{ score_socioeconomico_mx }}</span>
            {{ score_alto___bajo }}
          </b-row>
          <b-row class="align-items-center mb-3">
            <b class="font__heavy pr-2">{{ $t("navbarInfo.labelsubtipo") }}:</b>
            {{ subtipo }}
          </b-row>
        </b-form>
      </b-col>
      <b-col cols="2">
        <b-row class="align-items-center mb-3">
          <b-col>
            <b class="font__heavy pr-2">Estatus referido:</b>
            <b class="text-primary">
              <span>
                {{ tiporeferidor === null || tiporeferidor === "" ? "Ninguno" : tiporeferidor }}
              </span>
            </b>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-3">
          <b-col>
            <img src="../assets/images/international.png" width="30" style="opacity:.45;" />
            <b class="pl-2">
              <span>
                {{ internacional === null || internacional === "" ? "" : internacional }}
              </span>
            </b>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3" class="pl-">
        <b-row class="align-items-center mb-2">
          <b-col>
            <img src="../assets/images/enfermera-icon.png" width="30" />
            <b class="pl-2">{{ ownerID }}</b>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-2">
          <b-col>
            <img src="../assets/images/doctor-icon.png" width="30" alt="" />
            <b class="pl-2">{{ doc_info }}</b>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-2">
          <b-col>
            <img src="../assets/images/enfermera-icon2.png" width="30" />
            <b class="pl-2">{{ med_assistance }}</b>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" align-self="center" align="end">
        <MenuUser />
      </b-col>
    </b-row>

    <!-- <b-row
      class="text-center navbar__main flex-nowrap d-flex w-full"
      align-v="center"
    >
      <b-col class="select-sede">
        <span class="font__heavy">{{ $t("navbarInfo.sedeOrigen") }}</span>
        <div>
          <b-dropdown
            id="dropdown-1"
            size="sm"
            :text="sedeOrigen"
            class="m-md-2"
            variant="outline-white"
            :disabled="pathName === 'Recipe'"
          >
            <template v-if="country === 'US'">
              <div class="header__dropdown">
                <img src="../assets/images/eeuu-flag.svg" alt="" />
                <b>{{ $t("navbarInfo.USA") }}</b>
              </div>
              <div
                v-for="(item, index) in origenUS"
                :key="index"
                class="item__dropdown"
              >
                <b-dropdown-item
                  :data-name="item"
                  @click="clickSede(item, 'origen')"
                >
                  {{ item.label }}
                </b-dropdown-item>
              </div>
            </template>
            <template v-else>
              <div class="header__dropdown">
                <img src="../assets/images/mexico-flag.svg" alt="" />
                <b>México</b>
              </div>
              <div
                v-for="(item, index) in origenMX"
                :key="index + 5"
                class="item__dropdown"
              >
                <b-dropdown-item
                  :data-name="item"
                  @click="clickSede(item, 'origen')"
                >
                  {{ item.label }}
                </b-dropdown-item>
              </div>
            </template>
          </b-dropdown>
        </div>

        <span class="font__heavy">{{ $t("navbarInfo.sedeDestino") }}</span>
        <div>
          <b-dropdown
            id="dropdown-2"
            size="sm"
            :text="sedeDestino"
            class="m-md-2"
            variant="outline-white"
          >
            <div class="header__dropdown">
              <img src="../assets/images/eeuu-flag.svg" alt="" />
              <b>{{ $t("navbarInfo.USA") }}</b>
            </div>
            <div
              v-for="(item, index) in destinoUS"
              :key="index"
              class="item__dropdown"
            >
              <b-dropdown-item
                :data-name="item"
                @click="clickSede(item, 'destino')"
              >
                {{ item.label }}
              </b-dropdown-item>
            </div>

            <b-dropdown-divider></b-dropdown-divider>
            <div class="header__dropdown">
              <img src="../assets/images/mexico-flag.svg" alt="" />
              <b>México</b>
            </div>
            <div
              v-for="(item, index) in destinoMX"
              :key="index + 5"
              class="item__dropdown"
            >
              <b-dropdown-item
                :data-name="item"
                @click="clickSede(item, 'destino')"
              >
                {{ item.label }}
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>
      </b-col>

      <b-col class="select-sede">
        <b-col class="col__sspta">
          <img src="../assets/images/mujer-icon.png" width="30" alt="" />
          <p>
            <b>{{ nombrePaciente }}</b>
          </p>
        </b-col>
        <b-row class="col__sspta">
          <p>
            <b>NHC:</b>
            {{ NHC }}
          </p>
        </b-row>
        <div class="">
          <b>Perfil:</b>
          <span class="emoji">
            {{ score_azul_verde }} {{ score_socioeconomico_mx }}
          </span>
          {{ score_alto___bajo }}
        </div>
      </b-col>
      <b-col class="select-sede" style="margin-top:45px;">
        <b-col class="">
          <img
            src="../assets/images/international.png"
            width="30"
            alt=""
            style="opacity:.45;"
          />
          <p>
            <b>
              <span
                v-if="internacional === null || internacional === ''"
                style="color: transparent;"
              >
                x
              </span>
              <span v-else class="">{{ internacional }}</span>
            </b>
          </p>
        </b-col>
        <hr />
        <b-col class="">
          <p>
            <b>Estatus referido:</b>
          </p>
          <p>
            <b class="text-primary">
              <span v-if="tiporeferidor === null || tiporeferidor === ''">
                Ninguno
              </span>
              <span v-else>{{ tiporeferidor }}</span>
            </b>
          </p>
        </b-col>
      </b-col>
      <b-col class="select-sede" style="margin-top:45px;">
        <b-col class="">
          <img src="../assets/images/enfermera-icon.png" width="30" alt="" />
          <p>
            <b>{{ ownerID }}</b>
          </p>
        </b-col>
        <hr />
        <b-col class="">
          <img src="../assets/images/enfermera-icon2.png" width="30" alt="" />
          <p>
            <b>{{ med_assistance }}</b>
          </p>
        </b-col>
      </b-col>
      <b-col class="select-sede" style="">
        <b-col class="">
          <img src="../assets/images/doctor-icon.png" width="30" alt="" />
          <p>
            <b>{{ doc_info }}</b>
          </p>
        </b-col>
        <hr />
        <b-col></b-col>
      </b-col>
      <b-col md="3" xl="2" class="d-none d-xl-block justify-content-center">
        <MenuUser />
      </b-col>
    </b-row> -->
  </b-container>
</template>

<script>
import { API_HUBSPOT, API_ROOT } from "../Constants/Api";
import MenuUser from "./MenuUser.vue";
import { defaultConfigRol } from "./Admin/Rol/initialDataFormRol";
import { AlertForm, AlertFormLarge, ICON } from "../helpers/Alert";
import { ApiRequest } from "../helpers/ApiRequest";
export default {
  components: { MenuUser },

  name: "NavBarInfo",
  data: () => ({
    mail: "",
    imageUser: "",
    nombrePaciente: "",
    doc_info: "",
    med_assistance: "",
    NHC: "",
    sedeOrigen: "",
    sedeDestino: "",
    origenUS: [],
    destinoUS: [],
    origenMX: [],
    destinoMX: [],
    country: [],
    sucursalesUS: [],
    pathName: "",
    internacional: "",
    ownerID: "",
    como_nos_conociste: "",
    score_azul_verde: "",
    score_socioeconomico_mx: "",
    tiporeferidor: "",
    score_alto___bajo: "",
    subtipo:"",
    tipo:""
  }),

  methods: {
    formatName(fullName) {
      const parts = fullName
        .trim()
        .replace(/\s+/g, " ")
        .split(" ");

      const firstName = parts[0];

      const lastName1 = parts[parts.length - 2];
      const lastName2 = parts[parts.length - 1];

      if (parts.length === 2) {
        return `${firstName} ${lastName2}`;
      }

      return `${firstName} ${lastName1} ${lastName2}`;
    },
    //campos de hubspot para traer
    async fetchData() {
      try {
        const internationalData = await ApiRequest.post(`${API_HUBSPOT}getInternational`, {
          email: sessionStorage.getItem("email"),
        });

        // Obtener datos del propietario
        const ownerID = await this.getOwnerData(internationalData);

        // Obtener datos de HubSpot
        let internacional = internationalData.body.properties.paciente_internacional?.value || "";

        let score_azul_verde = internationalData.body.properties.score_azul_verde?.value || "";

        // Verifica el valor y asigna color
        if (score_azul_verde === "Azul" || score_azul_verde === "Blue") {
          score_azul_verde = "🔵";
        } else if (score_azul_verde === "Verde" || score_azul_verde === "Green") {
          score_azul_verde = "🟢";
        }

        let score_socioeconomico_mx =
          internationalData.body.properties.score_socioeconomico_mx?.value || "";
        let score_alto___bajo = internationalData.body.properties.score_alto___bajo?.value || "";

        // Verifica el valor y asigna color
        if (score_socioeconomico_mx === "Amarillo") {
          score_socioeconomico_mx = "🟡";
          score_alto___bajo = "";
        } else if (score_socioeconomico_mx === "Blanco") {
          score_socioeconomico_mx = "⚪";
          score_alto___bajo = "";
        } else if (score_socioeconomico_mx === "Intermedio") {
          score_socioeconomico_mx = "🔘";
          // Aquí validar con score alto/bajo
          //score_alto___bajo = internationalData.body.properties.score_alto___bajo?.value || '';
        } else if (score_socioeconomico_mx === "") {
          score_socioeconomico_mx = "";
          score_alto___bajo = "";
        }

        //verifica si es score alto/bajo
        // if(score_alto___bajo === 'Alto') {
        //   score_alto___bajo = 'Alto';

        // } else if (score_alto___bajo === 'Bajo') {
        //   //score_alto___bajo = 'Bajo';
        // } else {
        //   score_alto___bajo = '';
        // }
        if (score_alto___bajo === "Alto") {
          score_alto___bajo = this.$i18n.locale === "es" ? "Alto" : "High";
        } else if (score_alto___bajo === "Bajo") {
          score_alto___bajo = this.$i18n.locale === "es" ? "Bajo" : "Low";
        } else {
          score_alto___bajo = "";
        }
        this.ownerID = ownerID;
        this.internacional = internacional;
        this.score_azul_verde = score_azul_verde;
        this.score_socioeconomico_mx = score_socioeconomico_mx;
        this.score_alto___bajo = score_alto___bajo;
      } catch (err) {
        console.error(err);
      }
    },

    // Función para obtener datos del propietario
    async getOwnerData(internationalData) {
      try {
        const data_med = await ApiRequest.post(`${API_HUBSPOT}getOwner`, {
          id: internationalData.body.properties.medical_assistant.value,
        });

        return data_med.body.firstName + " " + data_med.body.lastName;
      } catch (err) {
        if (err === 401) {
          return "";
        }
        return "";
      }
    },

    async TipoReferidor() {
      try {
        const data = await ApiRequest.post(`${API_HUBSPOT}getInternational`, {
          email: sessionStorage.getItem("email"),
        });
        const respuesta = data.body.properties.tiporeferidor?.value || "";

        // Define un objeto de mapeo con arreglos de opciones como claves
        const mapeoSiglas = {
          "AL-Alianza": ["1"],
          "EM-Empresa": ["2"],
          "RF-Referidor": ["3"],
          "RE-Recomendados": ["4"],
          "RM-Recomendados": ["5"],
        };
        // Encuentra la clave en el objeto de mapeo que incluye la respuesta
        const claveEncontrada = Object.keys(mapeoSiglas).find((clave) => {
          if (Array.isArray(mapeoSiglas[clave])) {
            return mapeoSiglas[clave].includes(respuesta);
          } else {
            return mapeoSiglas[clave] === respuesta;
          }
        });

        // Devuelve la sigla correspondiente si se encuentra la clave, de lo contrario devuelve vacío
        if (claveEncontrada) {
          return claveEncontrada;
        } else {
          return "";
        }
      } catch (err) {
        if (err === 401) {
          return "";
        }
        return "";
      }
    },

    async clickSede(sede, item) {
      let obj = {
        name: sede.label,
        value: sede.value,
        country: sede.country,
      };
      if (item === "origen") {
        sessionStorage.setItem("arregloArticulos", JSON.stringify([]));
        sessionStorage.setItem("sedeOrigen", JSON.stringify(obj));
        this.$store.dispatch("sedeOrigen", obj);

        let tipo_origin=sessionStorage.getItem("subtipo")
        let response = await this.getConfigBranch(sede.label,tipo_origin);
        if (!response) return;
        this.sedeOrigen = sede.label;

        if (this.$store.state.rol.toLowerCase().includes("super admin")) return;
        let findCondByBranch = this.$store.state.allConfigByRol.find((el) =>
          el.sucursal.includes(this.sedeOrigen)
        );
        findCondByBranch = findCondByBranch ? findCondByBranch.vistas : defaultConfigRol;
        this.$store.dispatch("saveConfigRol", findCondByBranch);
      } else {
        this.sedeDestino = sede.label;
        sessionStorage.setItem("sedeDestino", JSON.stringify(obj));
      }
    },
    async getConfigBranch(sedeOrigen, subtipo) {
      try {
        let data = await ApiRequest.post(`${API_ROOT}pipelines/configXsucursal`, {
          sucursal: sedeOrigen,
          subtipo
        });
        this.$store.dispatch("allConfigBussiness", data.productos);
        return true;
      } catch (err) {
        if (err.status === 401) {
          AlertFormLarge(
            `${err.status}: ${err.statusText}`,
            JSON.stringify(err.data.detalle),
            ICON.WARNING
          );
          if (err.data.msg.includes("No se encontró una configuración de sucursal - pipeline.")) {
            this.$store.dispatch("allConfigBussiness", []);
            return true;
          }
        } else {
          AlertFormLarge(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
        return false;
      }
    },
    async getSedes() {
      try {
        const data = await ApiRequest.get(`${API_HUBSPOT}sedes`);
        let sedesDestino = data.sedes.find((el) => el.prop_name === "sede_destino");
        let sedesOrigen = data.sedes.find((el) => el.prop_name === "sede_origen");
        let ciudadUS = this.sucursalesUS;
        this.destinoUS = sedesDestino.datos
          .filter((el) => ciudadUS.includes(el.label))
          .map((el) => ({ label: el.label, value: el.value, country: "us" }));
        this.destinoMX = sedesDestino.datos
          .filter((el) => !ciudadUS.includes(el.label))
          .map((el) => ({ label: el.label, value: el.value, country: "mx" }));
        this.origenUS = sedesOrigen.datos
          .filter((el) => ciudadUS.includes(el.label))
          .map((el) => ({ label: el.label, value: el.value, country: "us" }));
        this.origenMX = sedesOrigen.datos
          .filter((el) => !ciudadUS.includes(el.label))
          .map((el) => ({ label: el.label, value: el.value, country: "mx" }));
        return [...this.destinoUS, ...this.destinoMX];
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async getSucursales() {
      try {
        let us = await ApiRequest.get(`${API_HUBSPOT}sucursalesUS`);
        this.sucursalesUS = us.sucursales;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
  },
  async mounted() {
    this.imageUser = this.$store.state.userResponse && this.$store.state.userResponse.picture;
    this.pathName = this.$route.name;
    const nombre = sessionStorage.getItem("nombre");
    const apellido = sessionStorage.getItem("apellido");
    const nombrePaciente = `${nombre} ${apellido}`;
    this.nombrePaciente = this.formatName(nombrePaciente);
    this.country = sessionStorage.getItem("country");
    let sede_origen =
      sessionStorage.getItem("sedeOrigen") && JSON.parse(sessionStorage.getItem("sedeOrigen"));
    this.sedeOrigen = sede_origen?.name;
    this.$store.dispatch("sedeOrigen", sede_origen || {});
    this.mail = sessionStorage.getItem("email");
    this.NHC = sessionStorage.getItem("nhc");

    this.doc_info = this.formatName(sessionStorage.getItem("doc_info"));
    this.med_assistance = this.formatName(sessionStorage.getItem("med_assistance"));
    const tipos = {
        1: 'Prospecto',
        2: 'Paciente',
        3: 'Donante Conocido',
        4: 'Donante',
        5: 'Colaborador',
        6: 'Donante Blue Donor',
     };
  this.tipo = tipos[sessionStorage.getItem("tipo")] || 'Valor no encontrado';
    const subtipos = {
        1: 'Fertilidad',
        2: 'Subrogado',
        3: 'More',
        4: 'Materno',
        5: 'Ovodon',
        6: 'Semen',
        7: 'Gestante'
     };
     

     this.subtipo = subtipos[sessionStorage.getItem("subtipo")] || 'Valor no encontrado';


    await this.getSucursales();

    await this.fetchData();

    //tipo referidor
    this.tiporeferidor = await this.TipoReferidor();

    const sedesDestino = await this.getSedes();
    if (sessionStorage.getItem("sedeDestino")) {
      this.sedeDestino = JSON.parse(sessionStorage.getItem("sedeDestino")).name;
    } else if (sessionStorage.getItem("sedeDestinoKey")) {
      let sede = sedesDestino.find((el) => el.value === sessionStorage.getItem("sedeDestinoKey"));
      this.sedeDestino = sede.label;
      sessionStorage.setItem(
        "sedeDestino",
        JSON.stringify({
          name: sede.label,
          value: sede.value,
          country: sede.country,
        })
      );
    } else {
      this.sedeDestino = "---Select Sede---";
    }
  },
};
</script>

<style scoped>
.navbar__main {
  /* margin: 30px 14px 5% 15px; */
  /* padding: 12px 0px; */
  background-color: white;
  font-size: 13px;
  border-radius: 1rem;
  width: 100%;
}
.col__sspta {
  display: flex;
  padding: 0;
  justify-content: center;
}
.col__sspta img,
.select-sede img {
  margin: auto;
  width: 30px;
  height: 30px;
}
.col__sspta span,
.col__sspta p {
  margin: 0.2rem;
}

.font__heavy {
  font-family: "futura-heavy";
}

/**** DropDown **** */
.header__dropdown {
  margin: 5px 10px;
  color: rgb(173, 181, 189);
}
.item__dropdown {
  padding: 0 0.5rem 0 2.5rem;
  font-size: 0.9rem;
}
.emoji {
  font-size: 1.5em;
}
</style>
